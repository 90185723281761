import React, { ReactNode } from 'react';

interface SocialButtonWrapperProps {
  children: ReactNode;
}

export function SocialIconWrapper({ children }: SocialButtonWrapperProps) {
  return (
    <span
      className="relative inline-block text-[0px] leading-none"
      data-testid="blog-social-icon-wrapper"
    >
      {children}
    </span>
  );
}
