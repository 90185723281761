import React, { useState } from 'react';
import { ReactTooltip } from '../../../SocialIcons/styles';
import { trackWithFingerprint } from '../../../../utils/trackWithFingerprint';
import { CopyLinkIcon } from './SocialIcons';
import { SocialIconWrapper } from './SocialIconWrapper';

export function ShareButton() {
  const [id] = useState('blog-share-button');
  const [tooltipOpen, setTooltipOpen] = useState<boolean | undefined>(false);

  return (
    <button
      id={id}
      type="button"
      onClick={() => {
        setTooltipOpen(true);
        navigator.clipboard?.writeText(window.location.href);
        trackWithFingerprint('CTA Clicked', {
          blog_share_type: 'Share Link',
          module: 'blog_share_icon'
        });
      }}
      onMouseLeave={() => setTooltipOpen(false)}
    >
      <SocialIconWrapper>
        <CopyLinkIcon
          alt="share icon"
          className="text-white hover:text-[#99E4D7]"
        />
      </SocialIconWrapper>
      <ReactTooltip
        anchorSelect={`#${id}`}
        content="Link copied!"
        place="top"
        isOpen={tooltipOpen}
      />
    </button>
  );
}
