import React from 'react';

export const TwitterShareIcon = ({ className, alt }: any) => (
  <svg
    className={className}
    width="41"
    height="41"
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label={alt}
    role="img"
  >
    <g filter="url(#filter0_d_7296_3265)">
      <rect
        x="4.5"
        y="4.5"
        width="32"
        height="32"
        rx="16"
        className="fill-current"
      />
      <path
        d="M27.967 16.6684C27.967 16.8649 27.967 16.9631 27.967 17.1596C27.967 22.1701 24.1354 28.0649 17.0617 28.0649C14.9003 28.0649 12.9354 27.4754 11.167 26.3947C11.4617 26.3947 11.7565 26.3947 12.1494 26.3947C13.9179 26.3947 15.588 25.8052 16.8652 24.7245C15.1951 24.7245 13.8196 23.5456 13.3284 22.0719C13.5249 22.0719 13.8196 22.1701 14.0161 22.1701C14.4091 22.1701 14.7038 22.1701 14.9986 22.0719C13.2302 21.6789 11.953 20.107 11.953 18.2403C12.4442 18.5351 13.0337 18.7315 13.7214 18.7315C12.7389 18.0438 12.0512 16.8649 12.0512 15.5877C12.0512 14.9 12.2477 14.2123 12.5424 13.6228C14.4091 15.9807 17.2582 17.4544 20.4021 17.6508C20.3038 17.3561 20.3038 17.0614 20.3038 16.7666C20.3038 14.7035 21.974 12.9351 24.1354 12.9351C25.2161 12.9351 26.1986 13.4263 26.8863 14.114C27.7705 13.9175 28.5565 13.6228 29.3424 13.2298C29.0477 14.114 28.4582 14.9 27.6723 15.3912C28.4582 15.293 29.1459 15.0965 29.8337 14.8017C29.3424 15.4894 28.6547 16.0789 27.967 16.6684Z"
        fill="#1D252C"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_7296_3265"
        x="0.5"
        y="0.5"
        width="40"
        height="40"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_7296_3265"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_7296_3265"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const LinkedInShareIcon = ({ className, alt }: any) => (
  <svg
    className={className}
    width="41"
    height="41"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label={alt}
    role="img"
  >
    <g filter="url(#filter0_d_6287_70328)">
      <rect
        x="4"
        y="4"
        width="32"
        height="32"
        rx="16"
        className="fill-current"
      />
      <path
        d="M23.021 17.3964C22.3823 17.3942 21.7494 17.518 21.1586 17.7606C20.5678 18.0033 20.0306 18.36 19.5778 18.8105C19.125 19.261 18.7655 19.7963 18.5198 20.3859C18.2741 20.9755 18.1471 21.6077 18.146 22.2464V27.1464C18.146 27.3453 18.225 27.5361 18.3657 27.6767C18.5063 27.8174 18.6971 27.8964 18.896 27.8964H20.646C20.8449 27.8964 21.0357 27.8174 21.1763 27.6767C21.317 27.5361 21.396 27.3453 21.396 27.1464V22.2464C21.3958 22.0192 21.4435 21.7946 21.536 21.5871C21.6285 21.3796 21.7637 21.1939 21.9327 21.0422C22.1018 20.8904 22.3009 20.776 22.5171 20.7064C22.7334 20.6367 22.9618 20.6135 23.1877 20.6381C23.5927 20.6891 23.9649 20.8869 24.2337 21.1942C24.5025 21.5014 24.6492 21.8965 24.646 22.3047V27.1464C24.646 27.3453 24.725 27.5361 24.8657 27.6767C25.0063 27.8174 25.1971 27.8964 25.396 27.8964H27.146C27.3449 27.8964 27.5357 27.8174 27.6763 27.6767C27.817 27.5361 27.896 27.3453 27.896 27.1464V22.2464C27.8949 21.6077 27.7679 20.9755 27.5222 20.3859C27.2765 19.7963 26.917 19.261 26.4642 18.8105C26.0114 18.36 25.4742 18.0033 24.8834 17.7606C24.2926 17.518 23.6597 17.3942 23.021 17.3964Z"
        fill="#231F20"
      />
      <path
        d="M15.896 18.1462H13.646C13.2318 18.1462 12.896 18.482 12.896 18.8962V27.1462C12.896 27.5605 13.2318 27.8962 13.646 27.8962H15.896C16.3102 27.8962 16.646 27.5605 16.646 27.1462V18.8962C16.646 18.482 16.3102 18.1462 15.896 18.1462Z"
        fill="#231F20"
      />
      <path
        d="M14.771 16.6462C15.8065 16.6462 16.646 15.8068 16.646 14.7712C16.646 13.7357 15.8065 12.8962 14.771 12.8962C13.7355 12.8962 12.896 13.7357 12.896 14.7712C12.896 15.8068 13.7355 16.6462 14.771 16.6462Z"
        fill="#231F20"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_6287_70328"
        x="0"
        y="0"
        width="40"
        height="40"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6287_70328"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_6287_70328"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const FacebookShareIcon = ({ className, alt }: any) => (
  <svg
    className={className}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label={alt}
    role="img"
  >
    <g filter="url(#filter0_d_6287_70327)">
      <rect
        x="4"
        y="4"
        width="32"
        height="32"
        rx="16"
        className="fill-current"
        shapeRendering="crispEdges"
      />
      <path
        d="M30.7325 20.0331C30.7325 14.0888 25.9111 9.33337 20.0328 9.33337C14.1546 9.33337 9.26709 14.1548 9.26709 20.0331C9.26709 25.3829 13.1639 29.8081 18.3156 30.6667V23.1373H15.6077V20.0331H18.3156V17.6554C18.3156 14.9474 19.9007 13.4944 22.3445 13.4944C23.5334 13.4944 24.7222 13.6925 24.7222 13.6925V16.3344H23.4013C22.0803 16.3344 21.684 17.193 21.684 17.9856V19.967H24.6562L24.1938 23.0712H21.684V30.6007C26.8357 29.8741 30.7325 25.449 30.7325 20.0331Z"
        fill="#1D252C"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_6287_70327"
        x="0"
        y="0"
        width="40"
        height="40"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6287_70327"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_6287_70327"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const CopyLinkIcon = ({ className, alt }: any) => (
  <svg
    className={className}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label={alt}
    role="img"
  >
    <g filter="url(#filter0_d_6287_70326)">
      <path
        d="M4 20C4 11.1634 11.1634 4 20 4C28.8366 4 36 11.1634 36 20C36 28.8366 28.8366 36 20 36C11.1634 36 4 28.8366 4 20Z"
        className="fill-current"
        shapeRendering="crispEdges"
      />
      <path
        d="M18.8617 25.9997H14.5951C13.1195 25.9997 11.8617 25.4797 10.8217 24.4397C9.78172 23.3997 9.26172 22.1419 9.26172 20.6663C9.26172 19.1908 9.78172 17.933 10.8217 16.893C11.8617 15.853 13.1195 15.333 14.5951 15.333H18.8617V17.4663H14.5951C13.7062 17.4663 12.9506 17.7775 12.3284 18.3997C11.7062 19.0219 11.3951 19.7775 11.3951 20.6663C11.3951 21.5552 11.7062 22.3108 12.3284 22.933C12.9506 23.5552 13.7062 23.8663 14.5951 23.8663H18.8617V25.9997ZM15.6617 21.733V19.5997H24.1951V21.733H15.6617ZM20.9951 25.9997V23.8663H25.2617C26.1506 23.8663 26.9062 23.5552 27.5284 22.933C28.1506 22.3108 28.4617 21.5552 28.4617 20.6663C28.4617 19.7775 28.1506 19.0219 27.5284 18.3997C26.9062 17.7775 26.1506 17.4663 25.2617 17.4663H20.9951V15.333H25.2617C26.7373 15.333 27.9951 15.853 29.0351 16.893C30.0751 17.933 30.5951 19.1908 30.5951 20.6663C30.5951 22.1419 30.0751 23.3997 29.0351 24.4397C27.9951 25.4797 26.7373 25.9997 25.2617 25.9997H20.9951Z"
        fill="#1D252C"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_6287_70326"
        x="0"
        y="0"
        width="40"
        height="40"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6287_70326"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_6287_70326"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
