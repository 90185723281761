import React, { useEffect, useState } from 'react';
import { ShareButton } from './ShareButton';
import {
  TwitterShareIcon,
  LinkedInShareIcon,
  FacebookShareIcon
} from './SocialIcons';
import { trackWithFingerprint } from '../../../../utils/trackWithFingerprint';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton
} from 'next-share';
import { SocialIconWrapper } from './SocialIconWrapper';
import cx from 'classnames';

interface SocialShareProps {
  seoTitle?: string;
  seoMetaDescription?: string;
  inline?: boolean;
  socialOptions?:
    | ('Twitter/X' | 'LinkedIn' | 'Facebook' | 'URL Copy')[]
    | 'all'
    | undefined;
  hideShareCopy?: boolean;
}

export function SocialShare({
  seoTitle,
  seoMetaDescription,
  inline = false,
  socialOptions,
  hideShareCopy = false
}: SocialShareProps) {
  const [currentPage, setCurrentPage] = useState('');

  useEffect(() => setCurrentPage(location?.href ?? ''), []);

  if (!socialOptions) {
    return null;
  }

  return (
    <div
      className={cx(
        `flex desktop:mr-auto desktop:h-14 desktop:min-w-[230px] desktop:rounded-2xl`,
        {
          'flex-row items-center gap-[10px]': inline,
          'flex-col items-start gap-[11.5px]': !inline
        }
      )}
    >
      {!hideShareCopy && (
        <p className="body-small mt-[-5px] !font-medium">Share via</p>
      )}
      <div
        className={cx(`flex gap-[2px]`, {
          'ml-[-4.5px]': !inline
        })}
      >
        {(socialOptions === 'all' || socialOptions.includes('Twitter/X')) && (
          <TwitterShareButton
            url={currentPage}
            title={seoTitle!}
            data-testid="twitter-share-button"
            onClick={() => {
              trackWithFingerprint('CTA Clicked', {
                blog_share_type: 'Twitter',
                module: 'blog_share_icon'
              });
            }}
          >
            <SocialIconWrapper>
              <TwitterShareIcon
                alt="twitter"
                className="text-white hover:text-[#99E4D7]"
              />
            </SocialIconWrapper>
          </TwitterShareButton>
        )}
        {(socialOptions === 'all' || socialOptions.includes('Facebook')) && (
          <FacebookShareButton
            url={currentPage}
            quote={seoMetaDescription!}
            onClick={() => {
              trackWithFingerprint('CTA Clicked', {
                blog_share_type: 'Facebook',
                module: 'blog_share_icon'
              });
            }}
          >
            <SocialIconWrapper>
              <FacebookShareIcon
                alt="facebook"
                className="text-white hover:text-[#99E4D7]"
              />
            </SocialIconWrapper>
          </FacebookShareButton>
        )}
        {(socialOptions === 'all' || socialOptions.includes('LinkedIn')) && (
          <LinkedinShareButton url={currentPage}>
            <SocialIconWrapper>
              <LinkedInShareIcon
                alt="linkedin"
                className="text-white hover:text-[#99E4D7]"
              />
            </SocialIconWrapper>
          </LinkedinShareButton>
        )}
        {(socialOptions === 'all' || socialOptions.includes('URL Copy')) && (
          <ShareButton />
        )}
      </div>
    </div>
  );
}
